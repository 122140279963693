
























































































































































// TODO: remove when time comes
// @ts-nocheck
import TextHighlight from "vue-text-highlight"
import { format } from "date-fns"

import { Game } from "@/types/game"

import HostsProvider from "@/providers/hosts.provider"

import {
  RtbButton,
  RtbLink,
  RtbCard,
  RtbCardBody,
  RtbSelect,
  RtbTable,
  RtbSpinner,
  RtbInlineHelp
} from "@/components/ui"

import SearchField from "@/components/SearchField.vue"

const GameSettings = () => import("@/components/Game/GameSettings.vue")

import { Firebase } from "@/helpers"

function getDefaultPagination() {
  return { page: 1, rowsPerPage: 20 }
}

import RunStatus from "@shared/enums/Game"

const Filter = {
  ALL: "ALL",
  MINE: "MINE",
  ...RunStatus
}

export default HostsProvider.extend({
  name: "GamesPage",
  components: {
    TextHighlight,
    RtbButton,
    RtbLink,
    RtbCard,
    RtbCardBody,
    RtbSelect,
    RtbTable,
    RtbSpinner,
    RtbInlineHelp,
    SearchField,
    GameSettings
  },
  data() {
    return {
      games: [] as Game[],
      initializingToGame: false,
      game: null,
      viewing: false,
      editing: false,
      adding: false,
      copying: false,
      query: "",
      pagination: getDefaultPagination(),
      totalItemsCount: Infinity,
      isAllFetched: false,
      filter: "MASTER",
      loading: false
    }
  },
  computed: {
    orgID() {
      return this.$store.state.orgID
    },
    user() {
      return this.$store.getters.user
    },
    filterOptions() {
      return Object.entries(Filter).map(([key, value]) => ({
        value: key,
        label: value
      }))
    },
    headers() {
      return [
        {
          text: "Id",
          value: "id",
          align: "left",
          sortable: false
        },
        {
          text: "Game",
          value: "name",
          align: "left",
          sortable: this.filter !== Filter.ALL
        },
        {
          text: "Start Time",
          value: "startTimestamp",
          align: "left",
          sortable: this.filter !== Filter.ALL
        },
        {
          text: "Start Time Text",
          value: "date",
          align: "left",
          sortable: false
        },
        {
          text: "Host",
          value: "hostUserID",
          align: "left",
          sortable: this.filter !== Filter.ALL
        },
        {
          text: "Version",
          value: "version",
          align: "left",
          sortable: this.filter !== Filter.ALL
        },
        {
          text: "Login",
          value: "code",
          align: "left",
          sortable: false
        },
        {
          text: "Action",
          value: "action",
          align: "left",
          sortable: false
        }
      ]
    },
    shouldDoLocalSearch(): boolean {
      return (
        this.isQueryValid &&
        (this.filter !== Filter.ALL ||
          (this.filter === Filter.ALL && this.isAllFetched))
      )
    },
    shouldDoServerPagination(): boolean {
      return this.filter === Filter.ALL && !this.isAllFetched
    },
    isQueryValid(): boolean {
      return this.query.length >= 2
    },
    orgs() {
      var arr = Object.entries(this.$store.getters.orgs)
      var newArr = []
      for (var i in arr) {
        var obj = {}
        obj.id = arr[i][0]
        obj.name = arr[i][1].name
        newArr.push(obj)
      }
      console.log("ALL ORG", newArr)
      return newArr
    }
  },
  watch: {
    pagination: {
      handler(newVal, oldVal) {
        if (!this.isQueryValid && !this.isAllFetched) {
          const isMovingBack = oldVal.page > newVal.page
          const options = isMovingBack
            ? { startAt: this.games[0]?.id }
            : { endAt: this.games[this.games.length - 1]?.id }
          this.getGames(options)
        }
      },
      deep: true
    },
    query() {
      // Always reset page when search changes
      this.pagination.page = 1
      if (this.shouldDoServerPagination) {
        this.getGames()
      }
    },
    filter() {
      this.games = []
      this.pagination.page = 1
      this.isAllFetched = false
      this.getGames()
    }
  },
  created() {
    const { id: gameID } = this.$route.query
    if (gameID) {
      this.query = gameID
    }
  },
  mounted() {
    this.getGames()
  },
  methods: {
    /**
     * This logic will be deleted as soon as we have Firestore.
     * Now it's overcomplicated.
     */
    // TODO(Andrew): remove `if` `else` `else if` hell
    // TODO(Andrew): refactor
    // TODO(Andrew): handle errors
    async getGames(options: { startAt?: string; endAt?: string } = {}) {
      const { orgID } = this.$store.state
      this.loading = true
      const service = await this.$services.get("game")

      if (this.isQueryValid && this.filter === Filter.ALL) {
        if (Firebase.isFirebaseKey(this.query)) {
          const game = await service.getGameByID(orgID, this.query)
          this.games = game ? [game] : []
        } else {
          this.games = await service.getGamesByName(orgID, this.query)
        }
      } else if (this.filter !== Filter.ALL && this.filter !== Filter.MINE) {
        this.games = await service.getGamesByRunStatus(
          orgID,
          RunStatus[this.filter]
        )
      } else if (this.filter === Filter.MINE) {
        this.games = await service.getGamesByHost(orgID, this.user.id)
      } else {
        if (this.isAllFetched) {
          this.getAllGames()
        } else {
          const { value, total } = await service.getGames(orgID, {
            startAt: options.startAt,
            endAt: options.endAt,
            page: this.pagination.page,
            pageSize: this.pagination.rowsPerPage
          })

          if (value) {
            this.games = value
          }
          if (total) {
            this.totalItemsCount = total
          }
        }
      }

      this.loading = false
    },
    getAllGames() {
      this.games = []
      this.loading = true
      this.$services
        .get("game")
        .then(service => service.getAllGames(this.$store.state.orgID))
        .then(games => {
          games.reverse()
          this.games = games
          this.isAllFetched = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    editable(game) {
      if (this.user.super) return true
      if (
        !this.user.super &&
        game.runStatus !== "Masters" &&
        game.runStatus !== "Blocks" &&
        game.runStatus !== "Tournament" &&
        game.runStatus !== "Templates"
      )
        return true
      return false
    },
    getHostName(userID: string): string | undefined {
      return this.HostsProvider_raw.find(host => host.id === userID)?.firstname
    },
    getKey({ clientID }) {
      return clientID
    },
    async pushToGameEdit({ id: gameID, clientID }: Game) {
      await this.$store.dispatch("auth/initializeToGame", { gameID, clientID })
      await this.$router.push(`/game/${gameID}/settings`)
    },
    async directGame({ id: gameID, clientID }) {
      if (!gameID) throw new Error("No game ID")
      if (!clientID) throw new Error("No client ID")
      this.initializingToGame = true
      try {
        await this.$store.dispatch("auth/initializeToGame", {
          gameID,
          clientID
        })
        const hasPreGame = this.$store.state.auth.hasPreGame
        const game = this.$store.state.game
        const room = game.started ? "game" : "pickteams"
        if (hasPreGame) {
          await this.$router.push(`/${room}/${clientID}${gameID}`)
        } else {
          await this.$router.push(`/${room}/${clientID}`)
        }
      } catch (e) {
        console.error(e)
      }
      this.initializingToGame = false
    },
    async maybeRemove(game) {
      if (confirm(`Are you sure you want to delete ${game.name}?`)) {
        await this.$store.dispatch("Games/removeGame", game)
        if (this.isAllFetched) {
          this.games = this.games.filter(g => g !== game)
        } else {
          this.getGames()
        }
      }
    },
    edit(game) {
      this.copying = false
      this.editing = true
      this.game = game
    },
    closeEdit() {
      this.copying = false
      this.editing = false
      this.game = null
    },
    copy(game) {
      this.copying = true
      this.editing = true
      this.game = game
    },
    initGameCreation() {
      this.editing = true
      this.adding = true
    },
    onGameUpdate(game: Game) {
      if (this.isAllFetched) {
        const index = this.games.findIndex(g => g.id === game.id)
        if (index !== -1) {
          this.$set(this.games, index, game)
        } else {
          this.games.unshift(game)
        }
      } else if (this.pagination.page === 1) {
        this.getGames()
      }
    },
    onGameCopy(game: Game, orgID: string) {
      if (orgID === this.orgID) {
        if (this.isAllFetched) {
          this.games.unshift(game)
        } else if (this.pagination.page === 1) {
          this.getGames()
        }
      }
    },
    formatDate(dateLike: string | undefined) {
      if (dateLike) {
        return format(new Date(dateLike), "MM.dd.yyyy HH:MM z")
      }

      return "Invalid Date"
    },
    getFilter() {
      return Filter
    }
  }
})

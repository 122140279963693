const Activity = require("./Activity")

module.exports = {
  MASTER: "Masters",
  BLOCK: "Blocks",
  TOURNAMENT: "Tournament",
  DELETED: "Deleted",
  STANDARD: "Standard",
  USER_TEMPLATE: "User Templates",
  ...Activity
}

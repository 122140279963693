var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h1", { staticClass: "sr-only" }, [_vm._v("Remotely Fun Games")]),
      _c(
        "rtb-card",
        [
          _c("rtb-card-body", [
            _c(
              "div",
              { staticClass: "rtb-d-flex align-center" },
              [
                _c(
                  "rtb-button",
                  {
                    staticClass: "mr-2",
                    attrs: { color: "success" },
                    on: { click: _vm.initGameCreation },
                  },
                  [
                    _c("svg-icon", { attrs: { name: "plus-regular" } }),
                    _vm._v("Add new Game"),
                  ],
                  1
                ),
                _vm.filter === _vm.getFilter().ALL
                  ? _c("rtb-inline-help", { staticClass: "mr-2" }, [
                      _vm._v(" Search is case sensitive"),
                    ])
                  : _vm._e(),
                _c("search-field", {
                  staticStyle: { width: "300px !important" },
                  model: {
                    value: _vm.query,
                    callback: function ($$v) {
                      _vm.query = $$v
                    },
                    expression: "query",
                  },
                }),
                _c("rtb-select", {
                  staticClass: "w-64",
                  attrs: {
                    options: _vm.filterOptions,
                    identity: "value",
                    label: "Filter",
                    placeholder: "Filter",
                    "hide-label": "",
                  },
                  model: {
                    value: _vm.filter,
                    callback: function ($$v) {
                      _vm.filter = $$v
                    },
                    expression: "filter",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("rtb-table"),
      _c("v-data-table", {
        staticClass: "rtb-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.games,
          pagination: _vm.pagination,
          search: _vm.shouldDoLocalSearch ? _vm.query : undefined,
          "total-items": _vm.shouldDoServerPagination
            ? _vm.totalItemsCount
            : undefined,
          loading: _vm.loading,
          "item-key": "id",
        },
        on: {
          "update:pagination": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("tr", [
                  _c("td", [
                    _c(
                      "span",
                      { staticClass: "text-white whitespace-no-wrap" },
                      [
                        _c(
                          "text-highlight",
                          { attrs: { queries: _vm.query } },
                          [_vm._v(_vm._s(item.id))]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "td",
                    [
                      _vm.editable(item)
                        ? _c(
                            "rtb-link",
                            {
                              attrs: { href: "#", role: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.pushToGameEdit(item)
                                },
                              },
                            },
                            [
                              _c(
                                "text-highlight",
                                { attrs: { queries: _vm.query } },
                                [_vm._v(_vm._s(item.name))]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "span",
                            [
                              _c(
                                "text-highlight",
                                { attrs: { queries: _vm.query } },
                                [_vm._v(_vm._s(item.name))]
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c("td", [
                    _vm._v(
                      " " + _vm._s(_vm.formatDate(item.startTimestamp)) + " "
                    ),
                  ]),
                  _c("td", [_vm._v(_vm._s(item.date))]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        item.hostUserID ? _vm.getHostName(item.hostUserID) : ""
                      )
                    ),
                  ]),
                  _c("td", [_vm._v(" " + _vm._s(item.version) + " ")]),
                  _c(
                    "td",
                    { attrs: { width: "96px" } },
                    [
                      _c(
                        "rtb-link",
                        {
                          attrs: {
                            href: "/gamelogin/" + _vm.getKey(item) + "?auth=0",
                            target: "_blank",
                          },
                        },
                        [_vm._v(" Login ")]
                      ),
                      _c("br"),
                      _c(
                        "rtb-link",
                        {
                          attrs: {
                            href:
                              "/gamelogin/" +
                              _vm.getKey(item) +
                              "?audit=1&auth=0",
                            target: "_blank",
                          },
                        },
                        [_vm._v(" Audit ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { attrs: { width: "188px" } },
                    [
                      _vm.filter !== _vm.getFilter().MASTER || _vm.user.super
                        ? _c(
                            "rtb-button",
                            {
                              staticClass: "mr-1",
                              attrs: { variant: "icon", "aria-label": "Edit" },
                              on: {
                                click: function ($event) {
                                  return _vm.edit(item)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: { name: "pen-regular" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "rtb-button",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            color: "secondary",
                            variant: "icon",
                            "aria-label": "Copy",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.copy(item)
                            },
                          },
                        },
                        [_c("svg-icon", { attrs: { name: "copy-regular" } })],
                        1
                      ),
                      _vm.user.super
                        ? _c(
                            "rtb-button",
                            {
                              staticClass: "mr-1",
                              attrs: {
                                color: "danger",
                                variant: "icon",
                                "aria-label": "Delete",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.maybeRemove(item)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: { name: "trash-regular" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.editable(item)
                        ? _c(
                            "rtb-button",
                            {
                              attrs: {
                                color: "grey",
                                variant: "icon",
                                loading: _vm.initializingToGame,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.directGame(item)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: { name: "sign-in-regular" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
          {
            key: "no-data",
            fn: function () {
              return [
                _vm.loading
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [_c("rtb-spinner")],
                      1
                    )
                  : _c("span", [_vm._v("No Data Available")]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.editing
        ? _c(
            "v-dialog",
            {
              attrs: {
                width: "1024px",
                persistent: "",
                "no-click-animation": "",
              },
              model: {
                value: _vm.editing,
                callback: function ($$v) {
                  _vm.editing = $$v
                },
                expression: "editing",
              },
            },
            [
              _c("GameSettings", {
                attrs: {
                  copying: _vm.copying,
                  adding: _vm.adding,
                  game: _vm.game,
                },
                on: {
                  update: _vm.onGameUpdate,
                  copy: _vm.onGameCopy,
                  close: _vm.closeEdit,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
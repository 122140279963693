import Vue, { VNode } from "vue"
import { sortBy } from "lodash"

import { UserRole, Host } from "@/types/user"

const HostsProvider = Vue.extend({
  name: "HostsProvider",
  data() {
    return {
      HostsProvider_loading: false,
      HostsProvider_raw: [] as Host[]
    }
  },
  computed: {
    HostsProvider_normalized(): Array<Host & { fullname: string }> {
      const result = this.HostsProvider_raw.filter(
        user => user.deleted !== true
      ).map(user => {
        user.fullname = `${user.firstname} ${user.lastname}`
        return user
      })

      return sortBy(result, "fullname")
    }
  },
  created() {
    this.HostsProvider_loading = true

    this.$services
      .get("user")
      .then(service => service.getUsersByRole(UserRole.Host))
      .then(hosts => {
        this.HostsProvider_raw = hosts
      })
      .finally(() => {
        this.HostsProvider_loading = false
      })
  },
  render(): VNode {
    return this.$scopedSlots.default({
      loading: this.HostsProvider_loading,
      hosts: this.HostsProvider_normalized
    })
  }
})

export default HostsProvider

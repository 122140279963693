



















import Vue, { PropType } from "vue"

import type { WithEvents } from "vue-typed-emit"
import { BTooltip } from "bootstrap-vue"
import shortid from "shortid"

import { RtbTextInput, RtbInputButton } from "@/components/ui"

export interface Events {
  input: string | undefined
}

export default (Vue as WithEvents<Events>).extend({
  name: "SearchField",
  components: {
    BTooltip,
    RtbTextInput,
    RtbInputButton
  },
  props: {
    value: {
      type: String as PropType<string | undefined>
    },
    buttonId: {
      type: String,
      default() {
        return shortid.generate()
      }
    }
  },
  data() {
    return {
      localValue: this.value,
      tooltipVisible: false
    }
  },
  watch: {
    value(value: string | undefined) {
      this.localValue = value
    },
    localValue(newValue: string | undefined, oldValue: string | undefined) {
      if (oldValue === "") {
        this.tooltipVisible = true
      }
    }
  },
  methods: {
    emitLocalValue() {
      this.$emit("input", this.localValue)
      this.tooltipVisible = false
    }
  }
})

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-64 mr-2" },
    [
      _c("rtb-text-input", {
        attrs: { label: "Search", placeholder: "Search", "hide-label": "" },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.emitLocalValue.apply(null, arguments)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function () {
              return [
                _c(
                  "rtb-input-button",
                  {
                    attrs: { id: _vm.buttonId, title: "Search" },
                    on: { click: _vm.emitLocalValue },
                  },
                  [_c("svg-icon", { attrs: { name: "search-regular" } })],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.localValue,
          callback: function ($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue",
        },
      }),
      _c(
        "b-tooltip",
        {
          attrs: {
            target: _vm.buttonId,
            show: _vm.tooltipVisible,
            placement: "right",
          },
          on: {
            "update:show": function ($event) {
              _vm.tooltipVisible = $event
            },
          },
        },
        [
          _vm._v("Press "),
          _c("kbd", [_vm._v("Enter")]),
          _vm._v(" or this button to apply the search query"),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }